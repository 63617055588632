














































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RunTask } from '@/store/interface/Task';
import { Submission } from '@/store/interface/Grade';
import { GradableQuizQuestion, QuizQuestion } from '@/store/interface/Quiz';
import JwlButton from '@/components/JwlButton.vue';
import QuizFacilitatorQuestion from '@/components/quiz/QuizFacilitatorQuestion.vue';
import { answerState } from '@/components/quiz/QuizAnswerHelpers';

const QuizFacilitatorSummary = () => import('@/components/quiz/QuizFacilitatorSummary.vue');

export interface ReviewQuestion {
  index: number;
  quizItem: GradableQuizQuestion;
}

@Component({
  components: {
    QuizFacilitatorSummary,
    QuizFacilitatorQuestion,
    JwlButton,
  },
})
export default class QuizFacilitatorReview extends Vue {
  @Prop(Object)
  runTask!: RunTask;

  @Prop(Object)
  studentSubmission!: Submission;

  selectedQuestion = -1;
  updatedQuestions: QuizQuestion[] | GradableQuizQuestion[] = [];
  taskCountStates = {
    correct: 0,
    incorrect: 0,
    partlyCorrect: 0,
    undecided: 0,
    total: 0,
  }

  goTo (targetIndex: number): void {
    this.selectedQuestion = targetIndex;
  }

  updateQuestion (data: GradableQuizQuestion): void {
    const index = Number(this.selectedQuestion.toString());
    this.updatedQuestions[index] = data;
    this.calculateTaskCountStates();
  }

  questionItemClasses (question: GradableQuizQuestion | QuizQuestion, index: number): Record<string, boolean> {
    const questionState = this.answerState(question);
    return {
      'quiz-facilitator-review__question-item--correct': questionState === 'correct' || questionState === 'partlyCorrect',
      'quiz-facilitator-review__question-item--review': questionState === 'review',
      'quiz-facilitator-review__question-item--incorrect': questionState === 'incorrect',
      'quiz-facilitator-review__question-item--selected': this.selectedQuestion === index,
    };
  }

  twoDigit (number: number): string {
    if (number < 10) {
      return `0${number}`;
    }

    return number.toString();
  }

  answerState (question: GradableQuizQuestion | QuizQuestion): ReturnType<typeof answerState> {
    return answerState(question);
  }

  calculateTaskCountStates (): void {
    let correct = 0;
    let incorrect = 0;
    let partlyCorrect = 0;
    let undecided = 0;

    this.updatedQuestions.forEach((question) => {
      const state = answerState(question);
      if (state === 'incorrect') {
        incorrect += 1;
      } else if (state === 'correct') {
        correct += 1;
      } else if (state === 'review') {
        undecided += 1;
      } else if (state === 'partlyCorrect') {
        partlyCorrect += 1;
      }
    });
    const total = this.updatedQuestions.length;

    this.taskCountStates = {
      correct,
      incorrect,
      partlyCorrect,
      undecided,
      total,
    };
  }

  get isGradePublished (): boolean {
    return !!this.studentSubmission && !!this.studentSubmission.grade && !!this.studentSubmission.grade.date;
  }

  get reviewQuestions (): ReviewQuestion[] {
    const questionsForReview: ReviewQuestion[] = [];

    this.updatedQuestions.forEach((quizItem: QuizQuestion | GradableQuizQuestion, index: number) => {
      if ('ignoreCase' in quizItem) {
        const itemState = this.answerState(quizItem);
        if (itemState === 'review') {
          questionsForReview.push({
            index,
            quizItem,
          });
        }
      }
    });

    return questionsForReview;
  }

  get question (): GradableQuizQuestion | null {
    if (this.selectedQuestion >= 0) {
      const quizItem = this.updatedQuestions[this.selectedQuestion];
      if (quizItem && 'ignoreCase' in quizItem) {
        return quizItem;
      }
    }
    return null;
  }

  get pageNumber (): string {
    return this.twoDigit(this.selectedQuestion + 1);
  }

  get lastPageNumber (): number {
    return this.updatedQuestions.length;
  }

  get lastPage (): string {
    return this.twoDigit(this.lastPageNumber);
  }

  get correctPercentage (): number {
    const { correct, partlyCorrect, total } = this.taskCountStates;
    if (total > 0) {
      const correctPercentage = ((correct + (partlyCorrect * 0.5)) / total) * 100;
      return Math.round(correctPercentage) / 100;
    }

    return -1;
  }

  get correctPercent (): string {
    const percent = this.correctPercentage;
    if (percent >= 0) {
      return percent.toLocaleString('en', { style: 'percent' });
    }

    return 'Err';
  }

  get recommendedPoints (): string {
    if (this.correctPercentage >= 0) {
      const { maxScore, halfScore } = this.runTask;
      let recommended = this.correctPercentage * maxScore;
      if (halfScore) {
        let totalNumber = Math.floor(recommended);
        const fraction = recommended % 1;
        if (fraction >= 0.75) {
          totalNumber += 1;
        } else if (fraction >= 0.25) {
          totalNumber += 0.5;
        }

        recommended = totalNumber;
      } else {
        recommended = Math.round(recommended);
      }

      return recommended.toString();
    }

    return 'Err';
  }

  created (): void {
    if (this.studentSubmission.quiz) {
      this.updatedQuestions = this.studentSubmission.quiz;
      this.calculateTaskCountStates();
    }
  }
}
