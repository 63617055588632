/* eslint-disable import/prefer-default-export */
import { GradableQuizQuestion, QuizQuestion } from '@/store/interface/Quiz';

export function answerState (question: GradableQuizQuestion | QuizQuestion): 'correct' | 'partlyCorrect' | 'incorrect' | 'review' | string {
  let response = null;
  let found = 0;
  let notFound = 0;
  let shouldHaveFound = 0;

  if ('answerIsCorrect' in question && question.answerIsCorrect) {
    response = question.answerIsCorrect;
  } else if ('correctAnswerIds' in question && question.correctAnswerIds !== undefined) {
    shouldHaveFound = question.correctAnswerIds.length;
    question.correctAnswerIds.forEach((correctAnswerId) => {
      if (question.answerIds !== undefined && question.answerIds.includes(correctAnswerId)) {
        found += 1;
      } else {
        notFound += 1;
      }
    });
  } else if ('answerIsCorrect' in question && question.answerString !== undefined) {
    shouldHaveFound = 1;

    if (!question.answerIsCorrect && 'answerString' in question && question.correctAnswerStrings !== undefined) {
      if (question.ignoreCase) {
        const foundIndex = question.correctAnswerStrings.findIndex((correctAnswerString) => correctAnswerString.toLowerCase() === question.answerString?.toLowerCase());
        if (foundIndex >= 0) {
          found = 1;
        } else {
          response = 'review';
        }
      } else {
        const foundIndex = question.correctAnswerStrings.findIndex((correctAnswerString) => correctAnswerString === question.answerString);
        if (foundIndex >= 0) {
          found = 1;
        } else {
          response = 'review';
        }
      }
    }
  } else if ('correctAnswerPairs' in question && question.correctAnswerPairs) {
    question.correctAnswerPairs.forEach((correctAnswerPair) => {
      if (correctAnswerPair !== undefined && question.answerDragAndDrop !== undefined) {
        shouldHaveFound += 1;
        const correctPair = question.answerDragAndDrop.find((answerPair) => answerPair !== undefined && answerPair.length === 2 && answerPair.includes(correctAnswerPair[0]) && answerPair.includes(correctAnswerPair[1]));
        if (correctPair) {
          found += 1;
        } else {
          notFound += 1;
        }
      }
    });
  }

  if (response !== null) {
    return response;
  }

  if (found === shouldHaveFound) {
    response = 'correct';
  } else if (notFound > 0 && found === 0) {
    response = 'incorrect';
  } else {
    response = 'review';
  }

  return response;
}
