






























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { GradableQuizQuestion, QuizAnswer } from '@/store/interface/Quiz';
import JwlButton from '@/components/JwlButton.vue';

const JwlSubmitRow = () => import('@/components/JwlSubmitRow.vue');

@Component({
  components: {
    JwlSubmitRow,
    JwlButton,
  },
})
export default class QuizFacilitatorQuestion extends Vue {
  @Prop(Object)
  gradableQuizQuestion!: GradableQuizQuestion;

  @Prop(Boolean)
  undecidedQuestion!: boolean;

  @Prop(Boolean)
  gradeIsPublished!: boolean;

  syncStatus = 0;
  error = null;
  showChoices = false;

  isSelectedAnswer (answer: QuizAnswer): boolean {
    if (this.gradableQuizQuestion.answerIds) {
      return this.gradableQuizQuestion.answerIds.includes(answer.id);
    }

    return false;
  }

  quizAnswerItemClass (answer: QuizAnswer): Record<string, boolean> {
    let isCorrectAnswer = false;
    if (this.gradableQuizQuestion.correctAnswerIds) {
      isCorrectAnswer = this.gradableQuizQuestion.correctAnswerIds.includes(answer.id);
    }
    return {
      'quiz-facilitator-question__answer-item--correct': isCorrectAnswer,
    };
  }

  submitDecision (accept: string): void {
    if (this.syncStatus !== 1) {
      this.syncStatus = 1;
      const { course, task, lmsId } = this.$route.params;
      const formData = new FormData();
      formData.set('student', lmsId);
      formData.set('submittedQuestion', this.gradableQuizQuestion.id.toString());
      formData.set('acceptAnswer', accept);
      this.$store.dispatch('postData', {
        url: `gradebook/${course}/quiz/${task}`,
        formData,
      }).then((data) => {
        this.$emit('update-question', data);
        this.syncStatus = 2;
        setTimeout(() => {
          this.syncStatus = 0;
          this.showChoices = false;
        }, 2500);
      }).catch((e) => {
        this.error = e;
        this.syncStatus = -1;
      });
    }
  }

  toggleShowChoices (): void {
    this.showChoices = !this.showChoices;
  }

  getAnswerForItem (answerId: number): string {
    const answer = this.gradableQuizQuestion.answerOptions.find((answerOption) => answerOption.id === answerId);
    if (answer) {
      return answer.answer;
    }

    return `Answer not found for ID ${answerId}`;
  }

  dragPairStateClass (dragDropPair: number[]): Record<string, boolean> {
    return {
      'quiz-facilitator-question__drag-drop-item--correct': this.dragPairState(dragDropPair),
    };
  }

  dragPairState (dragPairState: number[]): boolean {
    if (this.gradableQuizQuestion.correctAnswerPairs) {
      const correctPair = this.gradableQuizQuestion.correctAnswerPairs.find((correctAnswerPair) => correctAnswerPair.length === 2 && correctAnswerPair.includes(dragPairState[0]) && correctAnswerPair.includes(dragPairState[1]));
      return !!correctPair;
    }

    return false;
  }

  get answerCircleClass (): Record<string, boolean> {
    return {
      'quiz-facilitator-question__answer-circle--single': this.gradableQuizQuestion.questionType === 'single_response',
    };
  }

  get submittedQuizTextClass (): Record<string, boolean> {
    let isCorrect = false;

    if (this.gradableQuizQuestion.correctAnswerStrings) {
      if (this.gradableQuizQuestion.ignoreCase) {
        const foundIndex = this.gradableQuizQuestion.correctAnswerStrings.findIndex((correctAnswerString) => correctAnswerString.toLowerCase() === this.gradableQuizQuestion.answerString?.toLowerCase());
        if (foundIndex >= 0) {
          isCorrect = true;
        }
      } else {
        const foundIndex = this.gradableQuizQuestion.correctAnswerStrings.findIndex((correctAnswerString) => correctAnswerString === this.gradableQuizQuestion.answerString);
        if (foundIndex >= 0) {
          isCorrect = true;
        }
      }
    }

    return {
      'quiz-facilitator-question__submitted-text--correct': isCorrect,
      'quiz-facilitator-question__submitted-text--incorrect': !isCorrect,
    };
  }

  created (): void {
    this.showChoices = this.undecidedQuestion;
  }
}
